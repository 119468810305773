<template>
	<div class="pageContainer">
		<web-header @reload='reload' :userName='name'></web-header>
		<!-- 轮播图 -->
		<div class="banner">
			<el-carousel trigger="click" v-if="webSiteObj">
				<el-carousel-item v-for="(item,i) in webSiteObj.slideshowListl" :key="i">
					<div style="height:100%">
						<img :src="webSiteObj.slideshowListl[i]" alt="">
					</div>
				</el-carousel-item>
			</el-carousel>
			<div v-if="!isLogin" class="login">
				<Login @reload='reload' />
			</div>
		</div>
		<div class="content main">

			<!-- 直播课堂 -->
			<!-- <div class="contentOne" style="margin-bottom: 50px" v-show="liveList.length >0">
				<div class="oneTop">
					<span class="spanStyle" @click="$router.push({path:'/live',query:{index:4}})">more ></span>
					<ul>
						<li v-for="it in twoList" :key="it.id" @click="litwoEvt(it)"
							:class="indexTwo === it.id ? 'isActive' : ''">
							{{it.name}}

							<span class="littleName">{{it.littleName}}</span>
							<div v-if="indexTwo === it.id" class="sanjiaox"></div>
						</li>
					</ul>
				</div>

				<div class="oneBottom" v-if="indexTwo === 1">
					<div class="oneBottomCard" v-for="it in liveList" :key="it.id">
						<div @click="toLive(it)">
							<img :src="it.surfacePlot" alt="">
						</div>
						<div class="cardBottom cardBottomZB">
							<h5>{{it.name}}</h5>
							<p>
								<span>{{it.beginTime}}</span>
							</p>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 通知公告 -->
			<div class="contentOne">
				<div class="oneTop">
					<ul>
						<li class="two isActive noticeactive">
							{{noticetitle}}

							<!-- <span class="littleName">{{it.littleName}}</span> -->

						</li>
					</ul>
					<span class="spanStyle" @click="$router.push({path:'/noticeCenter',query:{index:2}})">更多 ></span>
				</div>
				<div class="threeBottom animate__animated animate__fadeInLeft" v-if="indexThree === 1">
					<div class="threeBottomBanner">
						<el-carousel>
							<el-carousel-item v-for="(item,i) in imgList" :key="i">
								<div class="father" style="height:100%">
									<img v-if="item.url" :src="item.url" alt="123">
									<img v-else src="../assets/imgs/5.jpg" alt="">
									<div class="mater">{{item.name}}</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="threeRight">
						<div v-for="it in newsList" :key="it.id" @click="toDetail(it)">
							<h3 class="noticeClass">
								<div class="title">{{it.title}}</div>
								<div class="time">{{it.createTime}}</div>
							</h3>
							<p v-html="textOmitted(it.content)" class="newsStyle">
							</p>
						</div>
					</div>
				</div>
				<div class="threeBottom animate__animated animate__fadeInRight" v-if="indexThree === 2">
					<div class="threeBottomBanner">
						<el-carousel>
							<el-carousel-item v-for="(item,i) in imgList" :key="i">
								<div class="father" style="height:100%">
									<img v-if="item.url" :src="item.url" alt="123">
									<img v-else src="../assets/imgs/5.jpg" alt="">
									<div class="mater">{{item.name}}</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="threeRight">
						<div v-for="it in trainList" :key="it.id" @click="toDetail(it)">
							<h3 class="noticeClass">
								<div class="title">{{it.title}}</div>
								<div class="time">{{it.createTime}}</div>
							</h3>
							<p v-html="textOmitted(it.content)" class="newsStyle">
							</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 培训项目 -->
			<div class="contentOne animate__animated animate__shakeX" v-show="courseList.length >0">
				<div class="oneTop">
					<ul>
						<li v-for="(it,i) in oneList" :key="it.id" @click="liEvt(i,it.id)"
							:class="index === i  ? 'isActive' : ''">

							{{it.name}}
							<!-- <span class="littleName">{{it.littleName}}</span> -->
							<div v-if="index === i" class="sanjiaox"></div>
						</li>
					</ul>
					<span class="spanStyle" @click="$router.push({path:'/trainingProgram',query:{index:3}})">更多></span>
				</div>
				<div class="oneBottom animate__animated animate__backInRight">
					<div class="oneBottomCard" v-for="it in courseList" :key="it.id">
						<div @click="courseEvt(it)">
							<img :src="it.coverPicture" alt="">
						</div>
						<div class="cardBottom">
							<h5>{{it.name}}</h5>
							<p>
								<span>￥{{it.cost}}</span>
								<span v-if="it.isPay === 0&&it.studyEndTime>nowdate" @click="toPayEvt(it)"
									style="cursor: pointer;">立即购买</span>
								<span v-if="it.isPay === 0&&it.studyEndTime<nowdate" @click="end"
									style="cursor: pointer;color: #FFF;background: #ccc;border: none;">已结束</span>
								<span v-else-if="it.isPay === 1" @click="courseEvt(it)"
									style="cursor: pointer;">已购买</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 权威师资 -->
			<div class="contentFour " style="padding: 20px 0;" v-if="teacherList.length > 0">
				<div class="teacher_top">
					<span class="spanLeft">
						权威师资
						<!-- <span>Authoritative teachers</span> -->
					</span>
					<span class="spanRight"
						@click="$router.push({path:'/authoritativeTeachers',query:{index:5}})">更多></span>
				</div>
				<div class="fourBottom">
					<div class="fourCard" v-for="it in teacherList" :key="it.id">
						<div>
							<img :src="it.photo" alt="">
						</div>
						<div>
							<p class="teacher_name">{{it.teacherName}}</p>
							<p class="teacherJs">{{it.rank}}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 学习分站 -->
			<div class="child_site main" v-if="childWebSite.length > 0">
				<div class="child_site_header">
					<ul>
						<li class="isActive">
							学习分站
							<!-- <span style="font-size: 16px;">Learning substation</span> -->
							<div class="sanjiaox"></div>
						</li>
					</ul>
				</div>
				<div class="child_site_content">
					<template v-for="(item,index) in childWebSite">
						<div class="child_site_item" :key="index" v-if="item.img" @click="handleToChildPath(item.path)">
							<img :src="item.img" width="150" height="150" alt="">
						</div>
					</template>
				</div>
			</div>
			<!-- 挂件 -->
			<div class="fontBox" :class="isFiexd ? 'bmBoxOneActive' : 'fontBox'">
				<el-tooltip class="item" effect="dark" :content="QNumber" placement="left">
					<div class="QQbox">
						<p style="margin-bottom:-6px;height:1px"></p>
						<img src="../assets/index/QQ.png" alt="">
						<span>QQ</span>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="WxNumber" placement="left">
					<div class="QQbox">
						<p style="margin-bottom:-6px;height:1px"></p>
						<img src="../assets/index/wx.png" alt="">
						<span>微信</span>
					</div>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" :content="phoneNumber" placement="left">
					<div class="QQbox">
						<p style="margin-bottom:-6px;height:1px"></p>
						<img src="../assets/index/phone.png" alt="">
						<span>电话</span>
					</div>
				</el-tooltip>
			</div>
			<!-- 集体报名 -->
			<transition name="el-zoom-in-top">
				<div class="bmBoxOne" v-show="bmBox" :class="isFiexd ? 'bmBoxOneActive' : 'bmBoxOne'">
					<span @click="bmBox = false">
						<img src="../assets/index/del.png" alt="">
					</span>
					<img @click="handleToGroupRegistration" src="../assets/index/2.png" alt="">
				</div>
			</transition>
		</div>
		<el-backtop>
			<div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
        font-weight:bold
      }">
				<i class="el-icon-arrow-up"></i>
			</div>
		</el-backtop>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../components/webFooter.vue'
	import webHeader from '../components/webHeader.vue'
	import Login from '../views/login/index.vue'
	import {
		mapState
	} from 'vuex'
	export default {
		components: {
			webHeader,
			WebFooter,
			Login
		},
		data() {
			return {
				imgList: [],
				activeName: 'first',
				oneList: [],
				twoList: [{
					id: 1,
					name: '直播课堂',
					littleName: 'Live Class'
				}, ],
				index: 0,
				indexTwo: 1,
				indexThree: 1,
				bmBox: true,
				isFiexd: false,
				courseList: [],
				newsList: [],
				trainList: [],
				teacherList: [],
				QNumber: '',
				WxNumber: '',
				phoneNumber: '',
				// webSiteObj: {},
				liveList: [],
				name: '',
				childWebSite: [], //子站列表
				noticetitle: '',
				nowdate: ''

			}
		},
		watch: {
			'$route'(to, from) {
				this.$router.go(0);
			}
		},
		computed: {
			...mapState(['isLogin', 'webSiteObj']),

		},
		created() {
			//获取当前时间
			this.getNowFormatDate()
			this.noticetitle = localStorage.getItem('noticeTitle') || '新闻公告'

			//获取分类列表
			this.$http.post('course').then(res => {
				if (res.code === 200) {

					this.oneList = res.data.splice(0, 3)

				}
			})
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			this.getTeacherList();
			this.getCourseList();
			this.getNoticeList();
			this.getServiceInfo();
			this.getChildWebSite()
			// this.getLiveList();
			loading.close()
		},
		mounted() {
			//监听滚动事件
			window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			//获取当前时间
			getNowFormatDate() { //获取当前时间
				var date = new Date().getTime();
				// var seperator1 = "-";
				// var seperator2 = ":";
				// var month = date.getMonth() + 1<10? "0"+(date.getMonth() + 1):date.getMonth() + 1;
				// var strDate = date.getDate()<10? "0" + date.getDate():date.getDate();
				// var currentdate = date.getFullYear() + seperator1  + month  + seperator1  + strDate
				// 		+ " "  + date.getHours()  + seperator2  + date.getMinutes()
				// 		+ seperator2 + date.getSeconds();

				return this.nowdate = date;
			},
			//已结束
			end() {
				this.$message('该课程已结束！')
			},
			// 文本省略
			textOmitted(text) {
				if (this.judgeBrowser() === 'Chrome') {
					return text
				} else {
					let str = text.substr(0, 65)
					str += '...'
					return str
				}
			},
			// 判断当前浏览器
			judgeBrowser() {
				var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串

				var isOpera = userAgent.indexOf("Opera") > -1;
				//判断是否Opera浏览器
				if (isOpera) {
					return "Opera"
				};
				//判断是否Firefox浏览器
				if (userAgent.indexOf("Firefox") > -1) {
					return "FF";
				}
				//判断是否chorme浏览器
				if (userAgent.indexOf("Chrome") > -1) {
					return "Chrome";
				}
				//判断是否Safari浏览器
				if (userAgent.indexOf("Safari") > -1) {
					return "Safari";
				}
				//判断是否IE浏览器
				if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {

					return "IE";
				}
				//判断是否Edge浏览器
				if (userAgent.indexOf("Trident") > -1) {
					return "Edge";
				};
			},
			// 强制刷新
			reload(data) {
				this.isLogin = data
			},
			liEvt(it, id) {
				this.index = it
				if (this.isLogin) {
					//获取课程详情
					this.$http.post('getOrgCourse', {
						pageSize: 4,
						id
					}).then(res => {
						if (res.code === 200) {
							res.data.records.forEach(item => {
								let b = item.studyEndTime.split('-')
								let [a, c, d] = b
								let datas = a + '.' + c + '.' + d
								item.studyEndTime = new Date(datas).getTime()

							});
							this.courseList = res.data.records
						} else {
							this.$message.error(res.message)
						}
					})
				} else {
					this.$message.error('请先登录')
				}
			},
			litwoEvt(it) {
				this.indexTwo = it.id
			},
			lithreeEvt(it) {
				this.imgList = [];
				this.indexThree = it.id
				if (it.id == '1') {
					this.$http.post('noticeApi', {
						type: 1,
						status: '1',
						pageSize: 4
					}).then(res => {
						if (res.code === 200) {
							this.newsList = res.data.records
							this.newsList.map(it => {
								if (it.createTime) {
									it.createTime = it.createTime.split(' ')
									it.createTime = it.createTime[0].split('-')
									it.createTime = it.createTime[0] + '年' + it.createTime[1] + '月' + it
										.createTime[2] + '日'
									return it
								}
							})
						}
						this.newsList.forEach((item, index) => {
							if (index < 3) {
								const obj = {
									url: item.image,
									name: item.title
								}
								this.imgList.push(obj)
							}
						})
					})
				} else {
					this.$http.post('noticeApi', {
						type: 2,
						status: '1',
						pageSize: 4
					}).then(res => {
						if (res.code === 200) {
							this.trainList = res.data.records
							this.trainList.map(it => {
								if (it.createTime) {
									it.createTime = it.createTime.split(' ')
									it.createTime = it.createTime[0].split('-')
									it.createTime = it.createTime[0] + '年' + it.createTime[1] + '月' + it
										.createTime[2] + '日'
									return it
								}
							})
						}
						this.trainList.forEach((item, index) => {
							if (index < 3) {
								const obj = {
									url: item.image,
									name: item.title
								}
								this.imgList.push(obj)
							}
						})
					})
				}
			},
			//滚动事件
			handleScroll(e) {
				if (document.documentElement.scrollTop > 500) {
					this.isFiexd = true
				}
				if (document.documentElement.scrollTop <= 500) {
					this.isFiexd = false
				}
			},
			//立即购买
			toPayEvt(e) {
				// 保存id，防止刷新
				localStorage.setItem('cousreId', e.id)
				if (this.isLogin) {
					this.$router.push({
						path: '/trainingProgram/paymentOrder',
						query: {
							index: 3,
							type: 2,
							data: e
						}
					})
				} else {
					this.$message.error('请先登录')
				}
			},
			// 
			//跳转课程详情页面
			courseEvt(it) {
				if (this.isLogin) {
					this.$router.push({
						path: '/trainingProgram/courseDetails',
						query: {
							id: it.id,
							index: 3
						}
					})
				} else {
					this.$message.error('请先登录')
				}
			},
			//跳转公告详情
			toDetail(it) {
				localStorage.setItem('notice', JSON.stringify(it))
				this.$router.push({
					path: '/noticeCenter/noticeDetail',
					query: {
						index: 2
					}
				})
			},
			// 跳转子站
			handleToChildPath(path) {
				if(path.indexOf('http') === -1){
					window.location.href = 'https://'+path
				}else{
					window.location.href = path
				}
			},
			//去直播页面
			// toLive(it) {
			// 	if (!this.isLogin) return this.$message.error('请先登录')
			// 	this.$router.push({
			// 		path: '/livedetails',
			// 		query: {
			// 			id: it.id
			// 		}
			// 	})
			// },
			// 跳转集体报名
			handleToGroupRegistration() {
				this.$router.push({
					path: '/collectiveRegistration'
				})
			},
			// 获取子站站点
			getChildWebSite() {
				this.$http.post('getChildWebSite').then(res => {
					if (res.code == 200) {
						this.childWebSite = res.data.map(item => {
							return {
								path: item.url,
								img: item.jumpPic,
								title: item.name,
								id: item.id
							}
						})
					}
				})
			},
			//获取权威师资
			getTeacherList() {
				this.$http.post('teacherQuery', {
					pageNumber: 1,
					pageSize: 4
				}).then(res => {
					if (res.code === 200) {
						this.teacherList = res.data.records
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取课程列表
			getCourseList() {
				this.$http.post('getOrgCourse', {
					pageSize: 4
				}).then(res => {
					if (res.code === 200) {
						res.data.records.forEach(item => {
							let b = item.studyEndTime.split('-')
							let [a, c, d] = b
							let datas = a + '.' + c + '.' + d
							item.studyEndTime = new Date(datas).getTime()

						});
						this.courseList = res.data.records
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取通知公告
			getNoticeList() {
				this.$http.post('noticeApi', {
					type: 1,
					status: '1',
					pageSize: 4
				}).then(res => {
					if (res.code === 200) {

						this.newsList = res.data.records
						this.newsList.map(it => {
							if (it.createTime) {
								it.createTime = it.createTime.split(' ')
								it.createTime = it.createTime[0].split('-')
								it.createTime = it.createTime[0] + '年' + it.createTime[1] + '月' + it
									.createTime[2] + '日'
							}
							return it
						})
						this.newsList.forEach((item, index) => {
							if (index < 3) {
								const obj = {
									url: item.image,
									name: item.title
								}
								this.imgList.push(obj)
							}
						})
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取客服资料
			getServiceInfo() {
				this.$http.post('getServiceInfo').then(res => {
					if (res.code === 200) {
						res.data.forEach((it, index) => {
							if (index < 3) {
								if (it.contactWay === 1) {
									this.QNumber = it.contactNumber
								}
								if (it.contactWay === 2) {
									this.WxNumber = it.contactNumber
								}
								if (it.contactWay === 3) {
									this.phoneNumber = it.contactNumber
								}
							}
						})
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取直播课堂
			getLiveList() {
				this.$http.post('liveGetByPage', {
					pageNumber: 1,
					pageSize: 8
				}).then(res => {
					if (res.code === 200) {
						this.liveList = res.data.records
					} else {
						this.$message.error(res.message)
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.main {
		width: 1200px;
		margin: 0 auto;
	}

	.banner {
		width: 100%;
		height: 450px;
		margin-bottom: 100px;

		.el-carousel__container {
			height: 450px;
		}

		img {
			width: 100%;
			height: 450px;
		}
	}

	/deep/.el-carousel__button {
		position: relative;
		background: #E51E0B;
	}

	//选项卡样式
	/deep/.el-tabs__header {
		background: #041366;
		height: 62px;
		color: white;
	}

	.isActive {
		position: relative;
		background: #E51E0B;
	}


	/deep/.el-tabs__item {
		color: #fff;
		width: 353px;
		height: 62px;
		font-size: 30px;
		text-align: center;
		line-height: 62px;
		padding: 0px;
	}

	/deep/.el-tabs__nav-wrap::after {
		height: 0;
	}

	/deep/.el-tabs__active-bar {
		height: 0;
	}

	.contentOne {
		position: relative;
		margin-bottom: 40px;

		.oneTop {
			.noticeactive {
				padding-left: 20px;
			}
		}
	}

	.spanStyle {
		// position: absolute;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
		margin-right: 20px;
		// right: 20px;
		// top: 21px;
		cursor: pointer;
	}

	.oneTop {
		background: #041366;
		height: 62px;
		color: white;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		ul {
			display: flex;

			&:first-child(1) {
				padding-left: 73px;
			}

			li {
				color: #fff;
				// width: 353px;
				padding-left: 43px;
				padding-right: 20px;
				height: 62px;
				font-size: 30px;
				text-align: center;
				line-height: 62px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 16px;
					margin-left: 3px;
				}
			}
		}
	}

	.oneBottom {
		margin-top: 50px;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;

		.oneBottomCard {
			display: flex;
			flex-direction: column;
			margin: 0 10px;
			width: 24%;

			img {
				cursor: pointer;
			}

			.cardBottom {
				// width: 298px;
				width: 100%;
				height: 150px;
				background: #FFFFFF;
				border: 1px solid #DCDCDC;
				flex-grow: 1;

				h5 {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				p {
					font-size: 18px;
					font-weight: 500;
					color: #333333;
				}
			}

			.cardBottomZB {
				// width: 297px;
				left: 0;
			}

			.cardBottom:hover {
				box-shadow: 0px 3px 9px 0px rgba(54, 54, 54, 0.26);
			}

			img {
				width: 100%;
				height: 192px;
				display: block;
			}

			h5 {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin-left: 20px;
				margin-right: 20px;
			}

			p {
				display: flex;
				justify-content: space-between;
				line-height: 47px;
				margin-left: 20px;
				margin-right: 10px;

				span {
					display: block;
				}

				span:nth-child(2) {
					width: 158px;
					height: 47px;
					background: #E51E0B;
					color: #fff;
					font-weight: 500;
					font-size: 18px;
					text-align: center;
					line-height: 47px;
				}
			}
		}

		.oneBottomCard:nth-child(4) {
			margin-right: 0;
		}

		.oneBottomCard:nth-child(8) {
			margin-right: 0;
		}
	}

	.threeBottom {
		margin-top: 54px;
		display: flex;

		.threeBottomBanner {
			height: 373px;
			width: 575px;
			margin-right: 38px;

			.el-carousel__container {
				height: 373px;
			}

			.el-carousel__indicators--horizontal {
				left: 80%;

			}

			.el-carousel__indicators--horizontal .el-carousel__button {
				width: 14px;
				height: 14px;
				background-color: #fff;
				border-radius: 50%;
			}

			.el-carousel__indicator.is-active button {
				width: 14px;
				height: 14px;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		.threeRight {
			width: 780px;
			cursor: pointer;

			h3 {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 350;
				color: #E51E0B;
				margin: 0;
			}

			p {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 24px;
				margin: 12px 0;
			}
		}

	}

	.contentFour {
		margin-bottom: 100px;

		.teacher_top {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.spanLeft {
			font-size: 30px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #E51E0B;
			display: flex;
			align-items: center;

			span {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #999999;
				margin-left: 3px;
			}
		}

		.spanRight {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			cursor: pointer;
		}

		.fourBottom {
			display: flex;
			// justify-content: space-between;
			flex-wrap: nowrap;


			.fourCard {
				width: 224px;
				height: 292px;
				background: #FFFFFF;
				border: 1px solid #EEEEEE;
				border-radius: 10px;
				margin: 30px 10px 0;

				img {
					width: 153px;
					height: 153px;
					display: block;
					border-radius: 50%;
					margin: 5px auto;
				}

				p {
					text-align: center;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
				}

				.teacher_name {
					margin: 20px 0;
				}
			}

			.fourCard:hover {
				box-shadow: 0px 4px 38px 0px rgba(24, 24, 24, 0.11);
			}
		}
	}

	.child_site {

		// position: relative;
		// margin-bottom: 40px;
		.child_site_header {
			background: #041366;
			height: 62px;
			color: white;
			position: relative;

			ul {
				display: flex;

				li {
					color: #fff;
					width: 353px;
					height: 62px;
					font-size: 30px;
					text-align: center;
					line-height: 62px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;

					span {
						font-size: 12px;
						margin-left: 3px;
					}
				}
			}
		}

		.child_site_content {
			margin-top: 54px;
			display: flex;
			flex-wrap: wrap;

			.child_site_item {
				margin-right: 60px;
				margin-bottom: 60px;
				cursor: pointer;

				&:nth-child(6n) {
					margin-right: 0;
				}

				// img {
				// 	width: 150px;
				// 	height: 150px;
				// 	display: block;
				// }

				.title {
					font-size: 20px;
					font-weight: bold;
					color: #333333;
					line-height: 49px;
					box-shadow: 0px 0px 9px 0px rgba(54, 54, 54, 0.26);
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					width: 150px;
					padding: 0 15px;
				}
			}
		}
	}

	.fontBox {
		position: fixed;
		right: 40px;
		bottom: 300px;
		display: none;

		div {
			width: 70px;
			height: 66px;
			background: #041366;
			color: #fff;
			margin-bottom: 10px;
			text-align: center;
			font-size: 14px;
		}

		img {
			display: block;
			width: 30px;
			height: 30px;
			margin: 0 auto;
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}

	.bmBoxOne {
		width: 221px;
		height: 226px;
		// background: linear-gradient(-56deg, #FC492E 0%, #FDA431 100%);
		border-radius: 20px;
		position: fixed;
		bottom: 300px;
		left: 10px;
		display: none;
		z-index: 99;

		span {
			position: absolute;
			display: block;
			right: 15px;
			top: 5px;
			color: #fff;
			cursor: pointer;
		}

		p {
			width: 180px;
			font-size: 40px;
			font-family: ZhenyanGB;
			font-weight: 400;
			color: #E51E0B;
			text-align: center;
			margin: 0 auto;
			padding-top: 45px;
			margin-bottom: 16px;
		}

		div {
			width: 159px;
			height: 41px;
			background: linear-gradient(0deg, #FFDD89 0%, #FFF5D7 100%);
			border-radius: 15px;
			margin: 0 auto;
			text-align: center;
			line-height: 41px;
			font-size: 25px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #BB4A2F;
			cursor: pointer;
		}

		img {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}

	.bmBoxOneActive {
		display: block;
	}

	.newsStyle {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		p {
			img {
				width: 50px !important;
			}
		}
	}

	.noticeClass {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			width: 500px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	/deep/.el-tooltip__popper {
		background: #fff !important;
	}

	.fiexedCls {
		position: fixed;
		top: 0;
		z-index: 999;
		width: 100%;
	}

	.el-carousel__button {
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: red;
	}

	.el-carousel__indicator.is-active button {
		width: 25px;
		height: 15px;
		border-radius: 10px;
	}

	.teacherJs {
		padding: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.isActive .sanjiaox {
		display: inline-block;
		width: 0;
		height: 0;
		border: 13px solid transparent;
		border-top-color: #E51E0B;
		/* 在位置上的小偏差移动 */
		position: relative;
		top: 40px;
		left: -60px;
		// margin-right: 13px;
	}

	.el-tooltip__popper.is-dark {
		width: 200px;
		height: 66px;
		line-height: 46px;
		text-align: center;
		color: #fff;
		font-size: 20px;
		// padding: 10px 30px;
		background-color: #041366;
		border-radius: 10px;
	}

	.father {
		position: relative;
	}

	.mater {
		font-size: 18px;
		color: #fff;
		padding-left: 32px;
		line-height: 56px;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 56px;
		background-color: #000;
		opacity: 0.5;
		width: 100%;
		z-index: 111;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.el-carousel::v-deep .two .el-carousel__button {
		right: 0 !important;
		background-color: #fff;
	}

	.banner {
		position: relative;
	}

	.login {
		position: absolute;
		top: 20px;
		right: 100px;
		width: 394px;
		height: 405px;
		// background-color: pink;
		z-index: 11111;
	}
</style>
